function menuApp() {
  const nav = document.querySelector("#navegacion");
  const abrir = document.querySelector("#abrir");
  const cerrar = document.querySelector("#cerrar");

  abrir.addEventListener("click", () => {
    nav.classList.toggle("activo");
    abrir.style.display = "none";
  });

  cerrar.addEventListener("click", () => {
    nav.classList.toggle("activo");
    abrir.style.display = "flex";
  });

  // // Agregar un evento de escucha de clic al documento
  document.addEventListener("click", (event) => {
    // Verificar si el clic se realizó fuera del menú
    if (!nav.contains(event.target) && !abrir.contains(event.target)) {
      nav.classList.remove("activo");
      abrir.style.display = "flex";
    }
  });
}

menuApp();
